<template>
  <v-dialog persistent v-model="this.$store.state.selections.amenitiesDialog" fullscreen>
    <v-card>
      <v-app-bar
          fixed
          dark
          color="#00004d"
      >
        <v-btn
            icon
            dark
            @click="$store.state.selections.amenitiesDialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          <span>Select Amenities/Features</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
            class="hidden-sm-and-down"
            append-icon="search"
            label="Search"
            v-model="search"
            single-line
            hide-details
        ></v-text-field>
        <v-toolbar-items class="hidden-sm-and-down">
          <v-btn
              dark
              text
              @click="checkAll"
              v-if="amenitiesFilter.length > 0"
          >
            Check All
          </v-btn>
          <v-btn
              dark
              text
              @click="uncheckAll"
              v-if="amenitiesFilter.length > 0"
          >
            Uncheck All
          </v-btn>
          <v-btn
              dark
              text
              href="/setup/amenities?addModule=true"
              target="_blank"
          >
            Add
          </v-btn>
          <v-btn
              dark
              text
              @click="$emit('getAmenities')"
          >
            Refresh
          </v-btn>
          <v-btn
              dark
              text
              @click="$store.state.selections.amenitiesDialog = false"
          >
            Save
          </v-btn>
        </v-toolbar-items>
        <v-menu
            bottom
            :close-on-content-click="true"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                class="hidden-md-and-up"
                height="25"
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
            >
              Actions
            </v-btn>
          </template>

          <v-list>
            <v-list-item
                v-if="amenitiesFilter.length > 0">
              <v-btn
                  text
                  @click="checkAll"
              >
                Check All
              </v-btn>
            </v-list-item>
            <v-list-item
                v-if="amenitiesFilter.length > 0">
              <v-btn
                  text
                  @click="uncheckAll"
              >
                Uncheck All
              </v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn
                  text
                  href="/setup/amenities?addModule=true"
                  target="_blank"
              >
                Add
              </v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn
                  text
                  @click="$emit('getAmenities')"
              >
                Refresh
              </v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn
                  text
                  @click="$store.state.selections.amenitiesDialog = false"
              >
                Save
              </v-btn>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-app-bar>
      <v-app-bar
          fixed
          dark
          color="#00004d"
          class="mt-12 hidden-md-and-up"
      >

        <v-text-field
            append-icon="search"
            label="Search"
            v-model="search"
            single-line
            hide-details
        ></v-text-field>
      </v-app-bar>
      <v-card class="custom-margin-top">
        <v-card-text>
          <v-row>
            <v-treeview
                class="row custom-treeview"
                v-model="$store.state.selections.selectedAmenityIds"
                selectable
                open-on-click
                transition
                item-key="id"
                item-text="title"
                :search="search"
                :items="amenitiesFilter"
            ></v-treeview>
<!--            <v-col cols="12" sm="3" v-for="(item, key) in amenitiesFilter" :key="key">-->
<!--              <h3 v-html="item.title"></h3>-->
<!--              <v-checkbox-->
<!--                  v-if="item.children.length > 0"-->
<!--                  v-for="(child, ckey) in item.children" :key="ckey"-->
<!--                  :label="child.title"-->
<!--                  color="#00004d"-->
<!--                  v-model="$store.state.selections.selectedAmenityIds"-->
<!--                  :value="child.id"-->
<!--              ></v-checkbox>-->
<!--            </v-col>-->
          </v-row>
        </v-card-text>
      </v-card>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['amenitiesFilter', 'amenitiesIdsFilter'],
  data() {
    return {
      search: null
    }
  },
  methods: {
    checkAll() {
      this.$store.state.selections.selectedAmenityIds = this.amenitiesIdsFilter;
    },
    uncheckAll() {
      this.$store.state.selections.selectedAmenityIds = [];
    },
  }
};
</script>