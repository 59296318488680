<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12" sm="2" v-for="(item,i) in items" :key="i">
          <v-img
              @click="index=i; visible=true"
              style="cursor: pointer"
              :src="item.file_public_url"
              v-if="checkFileExtension(item.extension)"
          ></v-img>
        </v-col>
      </v-row>
    </v-container>

    <vue-easy-lightbox
        escDisabled
        moveDisabled
        :visible="visible"
        :imgs="imgs"
        :index="index"
        @hide="handleHide"
    ></vue-easy-lightbox>
  </div>
</template>

<script>
// Method.3 Local registration
import VueEasyLightbox from 'vue-easy-lightbox'

export default {
  name: 'gallery',
  props: ['items'],
  components: {
    VueEasyLightbox
  },
  data() {
    return {
      imgs: [],  // Img Url , string or Array of string
      visible: false,
      index: 0   // default: 0
    }
  },
  watch: {
    items: {
      handler() {
        this.setImgs()
      },
      deep: true
    }
  },
  mounted() {
    this.setImgs()
  },
  methods: {
    setImgs() {
      let that = this
      this.imgs = []
      this.items.forEach(v => {
        that.imgs.push(v.file_public_url);
      });
    },
    handleHide() {
      this.visible = false
    }
  }
}
</script>