<template>
  <v-col cols="12" sm="4">
    <h4>{{ moduleTitle }}</h4>
    <div class="form-group" v-for="(link,k) in links" :key="k" style="margin: 0">
      <v-text-field
          outlined
              dense
          v-show="link.module_title === moduleTitle"
          v-model="link.title"
          clearable
          label="Title"
          color="primary"
          maxlength="100"
          :counter="100"
          @keyup="update">
      </v-text-field>
      <v-text-field
          v-show="link.module_title === moduleTitle"
          clearable
          outlined
              dense
          label="URL"
          color="primary"
          maxlength="300"
          :counter="300"
          @keyup="update"
          v-model="link.url">
        <template v-slot:append>
          <v-btn
              @click="remove(k)"
              v-show="k || ( !k && links.length > 1)"
              small
              dark
              color="red"
              class="mr-1">
            <v-icon small dark>fas fa-minus-circle</v-icon>
          </v-btn>
          <v-btn
              @click="add(k)"
              v-show="k == links.length-1"
              small
              color="success"
              class="ma-0">
            <v-icon small dark>fas fa-plus-circle</v-icon>
          </v-btn>
        </template>
      </v-text-field>
    </div>
  </v-col>
</template>

<script>
import {bus} from "@/main";

export default {
  name: 'module-links',
  props: ['moduleOf', 'moduleFor', 'moduleTitle', 'moduleLinksOld'],
  data() {
    return {
      // uploadedLinksOld: [],
      uploadedReadyLinks: [],
      links: [
        {url: '', title: '', module_title: this.moduleTitle, model: this.moduleOf},
      ]
    }
  },
  watch: {
    links: {
      handler(v) {
        Object.assign(this.$store.state.uploads.uploadedStateLinks, {[this.moduleFor]: v});
      },
      deep: true
    },
    moduleLinksOld: {
      handler() {
        if (this.moduleLinksOld.length > 0) {
          this.links = this.moduleLinksOld
        } else {
          this.links = [
            {url: '', title: '', module_title: this.moduleTitle, model: this.moduleOf},
          ]
        }
        // this.uploadedLinksOld = this.moduleLinksOld;
      },
      deep: true
    }
  },
  mounted() {
    if (this.moduleLinksOld.length > 0) {
      this.links = this.moduleLinksOld
    } else {
      this.links = [
        {url: '', title: '', module_title: this.moduleTitle, model: this.moduleOf},
      ]
    }
    // this.uploadedLinksOld = this.moduleLinksOld;
    bus.$on("moduleLinksDeletedByModal", () => {
      this.links = [{url: '', title: '', module_title: this.moduleTitle, model: this.moduleOf}];
      this.$store.state.uploads.uploadedStateLinks = {};
    });
  },
  methods: {
    add() {
      this.links.push({url: "", title: '', module_title: this.moduleTitle, model: this.moduleOf});
    },
    update() {
    },
    remove(index) {
      this.links.splice(index, 1);
    },
  }
}
</script>