<template>
  <v-col cols="12">
    <h4>{{ moduleTitle }}</h4>
    <div class="form-group" v-for="(detail,k) in details" :key="k" style="margin: 0">
      <v-row>
        <v-col cols="12" sm="2">
          <v-card outlined
                  rounded>
            <v-card-text>
              <v-text-field clearable v-model="detail.title"
                            dense
                            maxLength="100"
                            :counter="100"
                            :readonly="moduleType!=='edit'"
                            label="Fee Title"></v-text-field>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" sm="2">
          <v-card outlined
                  rounded>
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="12" class="pt-0 pb-0">
                  <v-text-field clearable v-model="detail.amount"
                                dense
                                :ref="'listing_other_fee_amount_' + k"
                                @keyup="amountKeyUp"
                                :readonly="moduleType!=='edit'"
                                label="Amount"></v-text-field>
                </v-col>
                <!--                <v-col cols="12" sm="4" class="pt-0 pb-0">-->
                <!--                  <v-text-field clearable v-model="detail.percentage"-->
                <!--                                dense-->
                <!--                                :readonly="moduleType!=='edit'"-->
                <!--                                label="OR %"></v-text-field>-->
                <!--                </v-col>-->
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <!--        <v-col cols="12" sm="4">-->
        <!--          <v-card outlined-->
        <!--                  rounded>-->
        <!--            <v-card-text>-->
        <!--              <v-text-field clearable v-model="detail.per_sq_ft_fee"-->
        <!--                            dense-->
        <!--                            :readonly="moduleType!=='edit'"-->
        <!--                            label="Per SqFt Fee"></v-text-field>-->
        <!--            </v-card-text>-->
        <!--          </v-card>-->
        <!--        </v-col>-->

        <v-col cols="12" sm="2">
          <v-card outlined
                  rounded>
            <v-card-text>
              <v-select
                  clearable
                  dense
                  :readonly="moduleType!=='edit'"
                  :items="paidByFilter"
                  v-model="detail.paid_by"
                  item-text="title"
                  item-value="value"
                  label="Paid By"
              ></v-select>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" sm="6">
          <v-card outlined
                  rounded>
            <v-card-text>
              <v-autocomplete
                  :readonly="moduleType!=='edit'"
                  :ref="'contact_id_' + k"
                  :items="contactsFilter"
                  v-model="detail.contact_id"
                  item-text="full_name"
                  item-value="id"
                  clearable
                  deletable-chips
                  chips
                  dense
                  cache-items
                  small-chips
                  label="Paid To (min. 3 characters required)"
                  @keyup="contactsFilterKeyUp($event, k)"
              >
                <template v-slot:item="data">
                  <template v-if="typeof data.item !== 'object'">
                    <v-list-item-content v-text="data.item"></v-list-item-content>
                  </template>
                  <template v-else>
                    <v-list-item-content>
                      <v-list-item-title>
                        <h6 v-html="data.item.full_name"></h6>
                        <p v-html="'Phone(s):- ' + data.item.contact_phone_titles"></p>
                        <p v-html="'Email(s):- ' + data.item.contact_email_titles"></p>
                        <p v-html="'Role(s):- ' + data.item.contact_role_titles"></p>
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
                <template v-slot:append-outer>
                  <v-btn
                      @click="remove(k)"
                      v-show="k || ( !k && details.length > 1)"
                      small
                      dark
                      color="red"
                      class="mr-1">
                    <v-icon small dark>fas fa-minus-circle</v-icon>
                  </v-btn>
                  <v-btn
                      @click="add(k)"
                      v-show="k == details.length-1"
                      small
                      color="success"
                      class="ma-0">
                    <v-icon small dark>fas fa-plus-circle</v-icon>
                  </v-btn>
                </template>
              </v-autocomplete>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-divider></v-divider>
    </div>
  </v-col>
</template>

<script>
import {bus} from "@/main";
import {CONTACTS_SIMPLE_LIST} from "@/core/services/store/contacts.module";

export default {
  name: 'listing-fees',
  props: ['moduleOf', 'moduleTitle', 'listingFeesOld', 'moduleAmount', 'moduleType', 'item', 'parentPropertyType'],
  data() {
    return {
      paidByFilter: [
        {value: "Buyer/Tenant", title: 'Buyer/Tenant'},
        {value: "Seller/Landlord", title: 'Seller/Landlord'},
      ],
      isContactsLoaded: false,
      details: [
        {title: '', amount: '', percentage: '', per_sq_ft_fee: '', paid_by: '', contact_id: ''}
      ],
      contactsFilter: [],
    }
  },
  watch: {
    details: {
      handler(v) {
        this.$store.state.listings.uploadedListingFees = v;
      },
      deep: true
    },
    listingFeesOld: {
      handler() {
        if (this.listingFeesOld.length > 0) {
          let that = this;
          this.details = this.listingFeesOld
          setTimeout(function () {
            that.details.forEach(function (v, k) {
              v.amount = that.addTSBV(v.amount)
              v.per_sq_ft_fee = that.addTSBV(v.per_sq_ft_fee)
              that.$refs["contact_id_" + k][0]["items"] = [v.contact];
            });
          }, 1000)
        } else {
          this.details = [
            {title: '', amount: '', percentage: '', per_sq_ft_fee: '', paid_by: '', contact_id: ''}
          ]
        }
      },
      deep: true
    }
  },
  mounted() {
    if (this.listingFeesOld.length > 0) {
      let that = this;
      this.details = this.listingFeesOld
      setTimeout(function () {
        that.details.forEach(function (v, k) {
          v.amount = that.addTSBV(v.amount)
          // v.per_sq_ft_fee = that.addTSBV(v.per_sq_ft_fee)
          that.$refs["contact_id_" + k][0]["items"] = [v.contact];
        });
      }, 1000)
    } else {
      this.details = [
        {title: '', amount: '', percentage: '', per_sq_ft_fee: '', paid_by: '', contact_id: ''}
      ]
    }
    bus.$on("listingFeesDeletedByModal", () => {
      this.details = [
        {title: '', amount: '', percentage: '', per_sq_ft_fee: '', paid_by: '', contact_id: ''}
      ]
      this.$store.state.listings.uploadedListingFees = [];
    });
  },
  methods: {
    add() {
      this.details.push({title: '', amount: '', percentage: '', per_sq_ft_fee: '', paid_by: '', contact_id: ''});
    },
    update() {
    },
    remove(index) {
      this.details.splice(index, 1);
      // this.details.forEach(function (v, k) {
      //   that.$refs["contact_id_" + k][0]["items"] = [v.contact];
      // });
    },
    contactsFilterKeyUp(e, index) {
      clearTimeout(this.apiTimeout)
      this.contactsFilter = []
      let that = this
      this.apiTimeout = setTimeout(function () {
        if (e.target.value.length >= 3) {
          that.isContactsLoaded = true;
          that.$store
              .dispatch(CONTACTS_SIMPLE_LIST, {
                role: 'All',
                q: e.target.value
              })
              .then(data => {
                that.contactsFilter = that.contactsFilter.concat(data.records.contactsList)
                // this.$refs["contact_id_" + index][0]["items"] =
                //     data.records.contactsList;
                that.isContactsLoaded = false;
              });
        }
      }, 900);
    },
    amountKeyUp() {
      this.details.forEach((value, index) => {
        value.amount = this.addTSBV(value.amount)
      });
    },
    getPercentageByAmount(e, index) {
      if (e.target.value) {
        let value = parseFloat(e.target.value.replace(/,(?=\d{3})/g, ''));
        let amount = parseFloat(this.moduleAmount.replace(/,(?=\d{3})/g, ''));
        this.details[index].percentage = ((value / amount) * 100).toFixed(2)
      } else {
        this.details[index].percentage = ''
      }
    },
    getAmountByPercentage(e, index) {
      if (e.target.value) {
        let value = parseFloat(e.target.value.replace(/,(?=\d{3})/g, ''));
        let amount = parseFloat(this.moduleAmount.replace(/,(?=\d{3})/g, ''));
        this.details[index].amount = this.addTSBV(((value / 100) * amount).toFixed(2))
      } else {
        this.details[index].amount = ''
      }
    }
  }
}
</script>