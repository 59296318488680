<template>
  <div>
    <v-toolbar
        dark
        color="#00004d"
    >
      <v-btn
          icon
          dark
          @click="$emit('closeDetailDialog')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title class="hidden-sm-and-down pl-0" v-if="item.location_ref === 'project' && !item.location_ref_bp">
        <span v-html="item.purpose ? item.purpose.title : ''"></span>
        <v-icon v-if="item.purpose" dark x-small class="ml-1 mr-1">fas fa-angle-double-right</v-icon>

        <span v-html="item.country ? item.country.title : ''"></span>
        <v-icon v-if="item.country" dark x-small class="ml-1 mr-1">fas fa-angle-double-right</v-icon>

        <span v-html="item.city ? item.city.title : ''"></span>
        <v-icon v-if="item.city" dark x-small class="ml-1 mr-1">fas fa-angle-double-right</v-icon>

        <span
            v-html="(item.project && item.project.development && item.project.development.parent_development && item.project.development.parent_development.parent_development && item.project.development.parent_development.parent_development.parent_development && item.project.development.parent_development.parent_development.parent_development.parent_development) ? item.project.development.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
        <v-icon
            v-if="item.project && item.project.development && item.project.development.parent_development && item.project.development.parent_development.parent_development && item.project.development.parent_development.parent_development.parent_development && item.project.development.parent_development.parent_development.parent_development.parent_development"
            dark x-small class="ml-1 mr-1">fas fa-angle-double-right
        </v-icon>

        <span
            v-html="(item.project && item.project.development && item.project.development.parent_development && item.project.development.parent_development.parent_development && item.project.development.parent_development.parent_development.parent_development) ? item.project.development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
        <v-icon
            v-if="item.project && item.project.development && item.project.development.parent_development && item.project.development.parent_development.parent_development && item.project.development.parent_development.parent_development.parent_development"
            dark x-small class="ml-1 mr-1">fas fa-angle-double-right
        </v-icon>

        <span
            v-html="(item.project && item.project.development && item.project.development.parent_development && item.project.development.parent_development.parent_development) ? item.project.development.parent_development.parent_development.commercial_title : ''"></span>
        <v-icon
            v-if="item.project && item.project.development && item.project.development.parent_development && item.project.development.parent_development.parent_development"
            dark x-small class="ml-1 mr-1">fas fa-angle-double-right
        </v-icon>

        <span
            v-html="(item.project && item.project.development && item.project.development.parent_development) ? item.project.development.parent_development.commercial_title : ''"></span>
        <v-icon
            v-if="item.project && item.project.development && item.project.development.parent_development"
            dark
            x-small
            class="ml-1 mr-1">fas fa-angle-double-right
        </v-icon>

        <span
            v-html="item.project && item.project.development ? item.project.development.commercial_title : ''"></span>
        <v-icon v-if="item.project && item.project.development" dark x-small class="ml-1 mr-1">fas
          fa-angle-double-right
        </v-icon>

        <span v-html="item.project && item.project.title"></span>
        <v-icon v-if="item.unit_no && item.project" dark x-small class="ml-1 mr-1">fas fa-angle-double-right
        </v-icon>

        <span v-html="item.unit_no"></span>
      </v-toolbar-title>
      <v-toolbar-title class="hidden-sm-and-down pl-0" v-if="item.location_ref === 'development' && !item.location_ref_bp">
        <span v-html="item.purpose ? item.purpose.title : ''"></span>
        <v-icon v-if="item.purpose" dark x-small class="ml-1 mr-1">fas fa-angle-double-right</v-icon>

        <span v-html="item.country ? item.country.title : ''"></span>
        <v-icon v-if="item.country" dark x-small class="ml-1 mr-1">fas fa-angle-double-right</v-icon>

        <span v-html="item.city ? item.city.title : ''"></span>
        <v-icon v-if="item.city" dark x-small class="ml-1 mr-1">fas fa-angle-double-right</v-icon>

        <span
            v-html="(item.development && item.development.parent_development && item.development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development.parent_development) ? item.development.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
        <v-icon
            v-if="item.development && item.development.parent_development && item.development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development.parent_development"
            dark x-small class="ml-1 mr-1">fas fa-angle-double-right
        </v-icon>

        <span
            v-html="(item.development && item.development.parent_development && item.development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development) ? item.development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
        <v-icon
            v-if="item.development && item.development.parent_development && item.development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development"
            dark x-small class="ml-1 mr-1">fas fa-angle-double-right
        </v-icon>

        <span
            v-html="(item.development && item.development.parent_development && item.development.parent_development.parent_development) ? item.development.parent_development.parent_development.commercial_title : ''"></span>
        <v-icon
            v-if="item.development && item.development.parent_development && item.development.parent_development.parent_development"
            dark
            x-small
            class="ml-1 mr-1">fas fa-angle-double-right
        </v-icon>

        <span
            v-html="item.development && item.development.parent_development ? item.development.parent_development.commercial_title : ''"></span>
        <v-icon v-if="item.development && item.development.parent_development" dark x-small
                class="ml-1 mr-1">fas
          fa-angle-double-right
        </v-icon>

        <span v-html="item.development && item.development.commercial_title"></span>
        <v-icon v-if="item.unit_no && item.development" dark x-small class="ml-1 mr-1">fas fa-angle-double-right
        </v-icon>

        <span v-html="item.unit_no"></span>
      </v-toolbar-title>
      <v-toolbar-title class="hidden-sm-and-down pl-0" v-if="item.location_ref_bp">
        <span v-html="item.location_ref_bp"></span>
      </v-toolbar-title>
    </v-toolbar>
    <v-tabs v-model="activeTabDetail" background-color="#002366" left dark>
      <v-tabs-slider></v-tabs-slider>
      <v-tab>Basic Details</v-tab>
      <v-tab>Pricing</v-tab>
      <v-tab>Description</v-tab>
      <v-tab>Location</v-tab>
      <v-tab>SEO Handler</v-tab>
      <v-tab>Media</v-tab>
      <v-tab>Features</v-tab>

      <v-tab-item>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="4">
              <h6>Title:</h6>
              <p class="hidden-sm-and-down" v-html="item.title"></p>
              <p class="hidden-md-and-up" v-if="item.location_ref === 'project' && !item.location_ref_bp">
                <span v-html="item.purpose ? item.purpose.title : ''"></span>
                <v-icon v-if="item.purpose" x-small class="ml-1 mr-1">fas fa-angle-double-right
                </v-icon>

                <span v-html="item.country ? item.country.title : ''"></span>
                <v-icon v-if="item.country" x-small class="ml-1 mr-1">fas fa-angle-double-right
                </v-icon>

                <span v-html="item.city ? item.city.title : ''"></span>
                <v-icon v-if="item.city" x-small class="ml-1 mr-1">fas fa-angle-double-right</v-icon>

                <span
                    v-html="(item.project && item.project.development && item.project.development.parent_development && item.project.development.parent_development.parent_development && item.project.development.parent_development.parent_development.parent_development && item.project.development.parent_development.parent_development.parent_development.parent_development) ? item.project.development.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                <v-icon
                    v-if="item.project && item.project.development && item.project.development.parent_development && item.project.development.parent_development.parent_development && item.project.development.parent_development.parent_development.parent_development && item.project.development.parent_development.parent_development.parent_development.parent_development"
                    x-small class="ml-1 mr-1">fas fa-angle-double-right
                </v-icon>

                <span
                    v-html="(item.project && item.project.development && item.project.development.parent_development && item.project.development.parent_development.parent_development && item.project.development.parent_development.parent_development.parent_development) ? item.project.development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                <v-icon
                    v-if="item.project && item.project.development && item.project.development.parent_development && item.project.development.parent_development.parent_development && item.project.development.parent_development.parent_development.parent_development"
                    x-small class="ml-1 mr-1">fas fa-angle-double-right
                </v-icon>

                <span
                    v-html="(item.project && item.project.development && item.project.development.parent_development && item.project.development.parent_development.parent_development) ? item.project.development.parent_development.parent_development.commercial_title : ''"></span>
                <v-icon
                    v-if="item.project && item.project.development && item.project.development.parent_development && item.project.development.parent_development.parent_development"
                    x-small class="ml-1 mr-1">fas fa-angle-double-right
                </v-icon>

                <span
                    v-html="(item.project && item.project.development && item.project.development.parent_development) ? item.project.development.parent_development.commercial_title : ''"></span>
                <v-icon
                    v-if="item.project && item.project.development && item.project.development.parent_development"
                    x-small
                    class="ml-1 mr-1">fas fa-angle-double-right
                </v-icon>

                <span
                    v-html="item.project && item.project.development ? item.project.development.commercial_title : ''"></span>
                <v-icon v-if="item.project && item.project.development" x-small class="ml-1 mr-1">fas
                  fa-angle-double-right
                </v-icon>

                <span v-html="item.project && item.project.title"></span>
                <v-icon v-if="item.unit_no && item.project" x-small class="ml-1 mr-1">fas
                  fa-angle-double-right
                </v-icon>

                <span v-html="item.unit_no"></span>
              </p>
              <p class="hidden-md-and-up" v-if="item.location_ref === 'development' && !item.location_ref_bp">
                <span v-html="item.purpose ? item.purpose.title : ''"></span>
                <v-icon v-if="item.purpose" x-small class="ml-1 mr-1">fas fa-angle-double-right
                </v-icon>

                <span v-html="item.country ? item.country.title : ''"></span>
                <v-icon v-if="item.country" x-small class="ml-1 mr-1">fas fa-angle-double-right
                </v-icon>

                <span v-html="item.city ? item.city.title : ''"></span>
                <v-icon v-if="item.city" x-small class="ml-1 mr-1">fas fa-angle-double-right</v-icon>

                <span
                    v-html="(item.development && item.development.parent_development && item.development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development.parent_development) ? item.development.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                <v-icon
                    v-if="item.development && item.development.parent_development && item.development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development.parent_development"
                    x-small class="ml-1 mr-1">fas fa-angle-double-right
                </v-icon>

                <span
                    v-html="(item.development && item.development.parent_development && item.development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development) ? item.development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                <v-icon
                    v-if="item.development && item.development.parent_development && item.development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development"
                    x-small class="ml-1 mr-1">fas fa-angle-double-right
                </v-icon>

                <span
                    v-html="(item.development && item.development.parent_development && item.development.parent_development.parent_development) ? item.development.parent_development.parent_development.commercial_title : ''"></span>
                <v-icon
                    v-if="item.development && item.development.parent_development && item.development.parent_development.parent_development"
                    x-small
                    class="ml-1 mr-1">fas fa-angle-double-right
                </v-icon>

                <span
                    v-html="item.development && item.development.parent_development ? item.development.parent_development.commercial_title : ''"></span>
                <v-icon v-if="item.development && item.development.parent_development" x-small
                        class="ml-1 mr-1">fas
                  fa-angle-double-right
                </v-icon>

                <span v-html="item.development && item.development.commercial_title"></span>
                <v-icon v-if="item.view_o_and_u === 1 && item.unit_no && item.development" x-small
                        class="ml-1 mr-1">fas fa-angle-double-right
                </v-icon>

                <span v-if="item.view_o_and_u === 1" v-html="item.unit_no"></span>
              </p>
              <p class="hidden-md-and-up" v-if="item.location_ref_bp">
                <span v-html="item.location_ref_bp"></span>
              </p>
            </v-col>
            <v-col cols="12" sm="4">
              <h6>Pupose:</h6>
              <p v-html="item.purpose ? item.purpose.title: '-'"></p>
            </v-col>
            <v-col cols="12" sm="4">
              <h6>Property Type:</h6>
              <p v-html="item.property_type ? item.property_type.title: '-'"></p>
            </v-col>
            <v-col cols="12" sm="4" v-if="item.project_id">
              <h6>Project:</h6>
              <p>
                <span v-html="item.project.country ? item.project.country.title : ''"></span>
                <v-icon v-if="item.project.country" x-small class="ml-1 mr-1">fas
                  fa-angle-double-right
                </v-icon>

                <span v-html="item.project.city ? item.project.city.title : ''"></span>
                <v-icon v-if="item.project.city" x-small class="ml-1 mr-1">fas
                  fa-angle-double-right
                </v-icon>

                <span
                    v-html="(item.project.development && item.project.development.parent_development && item.project.development.parent_development.parent_development && item.project.development.parent_development.parent_development.parent_development && item.project.development.parent_development.parent_development.parent_development.parent_development && item.project.development.parent_development.parent_development.parent_development.parent_development.parent_development) ? item.project.development.parent_development.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                <v-icon
                    v-if="item.project.development && item.project.development.parent_development && item.project.development.parent_development.parent_development && item.project.development.parent_development.parent_development.parent_development && item.project.development.parent_development.parent_development.parent_development.parent_development && item.project.development.parent_development.parent_development.parent_development.parent_development.parent_development"
                    x-small class="ml-1 mr-1">fas fa-angle-double-right
                </v-icon>

                <span
                    v-html="(item.project.development && item.project.development.parent_development && item.project.development.parent_development.parent_development && item.project.development.parent_development.parent_development.parent_development && item.project.development.parent_development.parent_development.parent_development.parent_development) ? item.project.development.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                <v-icon
                    v-if="item.project.development && item.project.development.parent_development && item.project.development.parent_development.parent_development && item.project.development.parent_development.parent_development.parent_development && item.project.development.parent_development.parent_development.parent_development.parent_development"
                    x-small class="ml-1 mr-1">fas fa-angle-double-right
                </v-icon>

                <span
                    v-html="(item.project.development && item.project.development.parent_development && item.project.development.parent_development.parent_development && item.project.development.parent_development.parent_development.parent_development) ? item.project.development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                <v-icon
                    v-if="item.project.development && item.project.development.parent_development && item.project.development.parent_development.parent_development && item.project.development.parent_development.parent_development.parent_development"
                    x-small class="ml-1 mr-1">fas fa-angle-double-right
                </v-icon>

                <span
                    v-html="(item.project.development && item.project.development.parent_development && item.project.development.parent_development.parent_development) ? item.project.development.parent_development.parent_development.commercial_title : ''"></span>
                <v-icon
                    v-if="item.project.development && item.project.development.parent_development && item.project.development.parent_development.parent_development"
                    x-small class="ml-1 mr-1">fas fa-angle-double-right
                </v-icon>

                <span
                    v-html="(item.project.development && item.project.development.parent_development) ? item.project.development.parent_development.commercial_title : ''"></span>
                <v-icon
                    v-if="item.project.development && item.project.development.parent_development"
                    x-small class="ml-1 mr-1">fas fa-angle-double-right
                </v-icon>

                <span
                    v-html="item.project.development ? item.project.development.commercial_title : ''"></span>
                <v-icon v-if="item.project.development" x-small class="ml-1 mr-1">fas
                  fa-angle-double-right
                </v-icon>

                <span v-html="'<strong>' + item.project.title + '</strong>'"></span>
              </p>
            </v-col>
            <v-col cols="12" sm="4" v-if="item.development_id">
              <h6>Development:</h6>
              <p>
                <span v-html="item.development.country ? item.development.country.title : ''"></span>
                <v-icon v-if="item.development.country" x-small class="ml-1 mr-1">fas
                  fa-angle-double-right
                </v-icon>

                <span v-html="item.development.city ? item.development.city.title : ''"></span>
                <v-icon v-if="item.development.city" x-small class="ml-1 mr-1">fas
                  fa-angle-double-right
                </v-icon>

                <span
                    v-html="(item.development && item.development.development.parent_development && item.development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development.parent_development.parent_development) ? item.development.parent_development.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                <v-icon
                    v-if="item.development && item.development.parent_development && item.development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development.parent_development.parent_development"
                    x-small class="ml-1 mr-1">fas fa-angle-double-right
                </v-icon>

                <span
                    v-html="(item.development && item.development.parent_development && item.development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development.parent_development) ? item.development.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                <v-icon
                    v-if="item.development && item.development.parent_development && item.development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development.parent_development"
                    x-small class="ml-1 mr-1">fas fa-angle-double-right
                </v-icon>

                <span
                    v-html="(item.development && item.development.parent_development && item.development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development) ? item.development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                <v-icon
                    v-if="item.development && item.development.parent_development && item.development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development"
                    x-small class="ml-1 mr-1">fas fa-angle-double-right
                </v-icon>

                <span
                    v-html="(item.development && item.development.parent_development && item.development.parent_development.parent_development) ? item.development.parent_development.parent_development.commercial_title : ''"></span>
                <v-icon
                    v-if="item.development && item.development.parent_development && item.development.parent_development.parent_development"
                    x-small class="ml-1 mr-1">fas fa-angle-double-right
                </v-icon>

                <span
                    v-html="(item.development && item.development.parent_development) ? item.development.parent_development.commercial_title : ''"></span>
                <v-icon
                    v-if="item.development && item.development.parent_development"
                    x-small class="ml-1 mr-1">fas fa-angle-double-right
                </v-icon>

                <span v-html="'<strong>' + item.development.commercial_title + '</strong>'"></span>
              </p>
            </v-col>
            <v-col cols="12" sm="4">
              <h6>Completion Status:</h6>
              <p v-html="item.completion_status ? item.completion_status.title: '-'"></p>
            </v-col>
            <v-col cols="12" sm="4">
              <h6>Publishing Status:</h6>
              <p v-html="item.publishing_status ? item.publishing_status.title: '-'"></p>
            </v-col>
            <v-col cols="12" sm="4">
              <h6>Internal Layout:</h6>
              <p v-html="item.internal_unit_type ? item.internal_unit_type.title: '-'"></p>
            </v-col>
            <v-col cols="12" sm="4">
              <h6>External Layout:</h6>
              <p v-html="item.external_unit_type ? item.external_unit_type.title: '-'"></p>
            </v-col>
            <v-col cols="12" sm="4">
              <h6>Typical Floor Plan:</h6>
              <p v-html="item.typical_floor_plan ? item.typical_floor_plan.title: '-'"></p>
            </v-col>
            <v-col cols="12" sm="4" v-if="item.view_o_and_u === 1">
              <h6>Unit Number:</h6>
              <p v-html="item.unit_no ? item.unit_no: '-'"></p>
            </v-col>
            <v-col cols="12" sm="4">
              <h6>Number Of Bedrooms:</h6>
              <p v-html="item.no_of_bedrooms ? item.no_of_bedrooms: '-'"></p>
            </v-col>
            <v-col cols="12" sm="4">
              <h6>Number Of Bathrooms:</h6>
              <p v-html="item.no_of_bathrooms ? item.no_of_bathrooms: '-'"></p>
            </v-col>
            <v-col cols="12" sm="4">
              <h6>Floor:</h6>
              <p v-html="item.floor ? item.floor: '-'"></p>
            </v-col>
            <v-col cols="12" sm="4">
              <h6>Plot Area:</h6>
              <p v-html="item.plot_area ? addTSBV(item.plot_area) + ' (' + item.plot_area_unit + ')' : '-'"></p>
            </v-col>
            <v-col cols="12" sm="4">
              <h6>Built Up Area:</h6>
              <p v-html="item.built_up_area ? addTSBV(item.built_up_area) + ' (' + item.built_up_area_unit + ')' : '-'"></p>
            </v-col>
            <v-col cols="12" sm="4">
              <h6>Launch Date:</h6>
              <p v-html="item.launch_date ? formatMomentDate(item.launch_date) : '-'"></p>
            </v-col>
            <v-col cols="12" sm="4">
              <h6>Registration Date:</h6>
              <p v-html="item.registration_date ? formatMomentDate(item.registration_date) : '-'"></p>
            </v-col>
            <v-col cols="12" sm="4">
              <h6>Construction Started Date:</h6>
              <p v-html="item.construction_started_date ? formatMomentDate(item.construction_started_date) : '-'"></p>
            </v-col>
            <v-col cols="12" sm="4">
              <h6>Anticipated Completion Date:</h6>
              <p v-html="item.anticipated_completion_date ? formatMomentDate(item.anticipated_completion_date) : '-'"></p>
            </v-col>
            <v-col cols="12" sm="4">
              <h6>Handover Date:</h6>
              <p v-html="item.handover_date ? formatMomentDate(item.handover_date) : '-'"></p>
            </v-col>
            <v-col cols="12" sm="4">
              <h6>Street Name/Number:</h6>
              <p v-html="item.street_name_no ? item.street_name_no: '-'"></p>
            </v-col>
            <v-col cols="12" sm="4">
              <h6>Year Built:</h6>
              <p v-html="item.year_built ? item.year_built: '-'"></p>
            </v-col>
            <v-col cols="12" sm="4">
              <h6>Parking Number:</h6>
              <p v-html="item.parking_no ? item.parking_no: '-'"></p>
            </v-col>
            <v-col cols="12" sm="4">
              <h6>Number Of Parking:</h6>
              <p v-html="item.no_of_parking ? item.no_of_parking: '-'"></p>
            </v-col>
            <v-col cols="12" sm="4">
              <h6>Land Number:</h6>
              <p v-html="item.land_no ? item.land_no: '-'"></p>
            </v-col>
            <v-col cols="12" sm="4">
              <h6>Official Residence Number/Makani Number:</h6>
              <p v-html="item.official_residence_no ? item.official_residence_no: '-'"></p>
            </v-col>
            <v-col cols="12" sm="4">
              <h6>Electricity Meter Number:</h6>
              <p v-html="item.electricity_meter_no ? item.electricity_meter_no: '-'"></p>
            </v-col>
            <v-col cols="12" sm="4">
              <h6>Gas Meter Number:</h6>
              <p v-html="item.gas_meter_no ? item.gas_meter_no: '-'"></p>
            </v-col>
            <v-col cols="12" sm="4">
              <h6>Municipality Number:</h6>
              <p v-html="item.municipality_number ? item.municipality_number: '-'"></p>
            </v-col>
            <v-col cols="12" sm="4">
              <h6>Key Locations:</h6>
              <p v-html="item.key_location_titles ? item.key_location_titles: '-'"></p>
            </v-col>
            <v-col cols="12" sm="4" v-if="item.view_o_and_u === 1">
              <h6>Owners:</h6>
              <p v-html="item.owner_titles ? item.owner_titles: '-'"></p>
            </v-col>
            <v-col cols="12" sm="4">
              <h6>Monthly Cooling Providers:</h6>
              <p v-html="item.cooling_provider_titles ? item.cooling_provider_titles: '-'"></p>
            </v-col>
            <v-col cols="12" sm="4">
              <h6>Developer:</h6>
              <p v-html="item.developer ? item.developer.full_name: '-'"></p>
            </v-col>
            <v-col cols="12" sm="4">
              <h6>Views:</h6>
              <p v-html="item.view_titles ? item.view_titles : '-'"></p>
            </v-col>
            <v-col cols="12" sm="4">
              <h6>Amenities:</h6>
              <p v-html="item.amenity_titles ? item.amenity_titles : '-'"></p>
            </v-col>
            <v-col cols="12" sm="4">
              <h6>Road Locations:</h6>
              <p v-html="item.road_location_titles ? item.road_location_titles : '-'"></p>
            </v-col>
            <v-col cols="12" sm="4">
              <h6>Location Remarks:</h6>
              <p v-html="item.location_remarks_titles ? item.location_remarks_titles : '-'"></p>
            </v-col>
            <v-col cols="12" sm="4">
              <h6>Number Of Years:</h6>
              <p v-html="item.lease_no_of_years ? item.lease_no_of_years : '-'"></p>
            </v-col>
            <v-col cols="12" sm="4">
              <h6>Bidding Start Date:</h6>
              <p v-html="item.bidding_from_date ? item.bidding_from_date : '-'"></p>
            </v-col>
            <v-col cols="12" sm="4">
              <h6>Bidding End Date:</h6>
              <p v-html="item.bidding_to_date ? item.bidding_to_date : '-'"></p>
            </v-col>
            <v-col cols="12" sm="4">
              <h6>Short Term Rent Frequency:</h6>
              <p v-html="item.short_term_rent_frequency ? item.short_term_rent_frequency.title : '-'"></p>
            </v-col>

            <v-col cols="12" sm="4">
              <h6>Check In Time:</h6>
              <p v-html="item.short_term_rent_check_in_time ? item.short_term_rent_check_in_time : '-'"></p>
            </v-col>

            <v-col cols="12" sm="4">
              <h6>Check Out Time:</h6>
              <p v-html="item.short_term_rent_check_out_time ? item.short_term_rent_check_out_time : '-'"></p>
            </v-col>

            <v-col cols="12" sm="4">
              <h6>Number Of Adults:</h6>
              <p v-html="item.short_term_rent_no_of_adults ? item.short_term_rent_no_of_adults : '-'"></p>
            </v-col>

            <v-col cols="12" sm="4">
              <h6>Number Of Children:</h6>
              <p v-html="item.short_term_rent_no_of_children ? item.short_term_rent_no_of_children : '-'"></p>
            </v-col>
            <v-col cols="12" sm="4">
              <h6>Share Value:</h6>
              <p v-html="item.crowed_funding_share_value ? item.crowed_funding_share_value : '-'"></p>
            </v-col>
            <v-col cols="12" sm="4">
              <h6>Usage Type:</h6>
              <p v-html="item.plot_usage_type ? item.plot_usage_type.title : '-'"></p>
            </v-col>
            <v-col cols="12" sm="4">
              <h6>Permitted Height:</h6>
              <p v-html="item.plot_permitted_height ? item.plot_permitted_height : '-'"></p>
            </v-col>
            <v-col cols="12" sm="4">
              <h6>Floor Area Ratio (FAR):</h6>
              <p v-html="item.plot_far ? item.plot_far : '-'"></p>
            </v-col>
            <v-col cols="12" sm="4">
              <h6>Office Space:</h6>
              <p v-html="item.warehouse_office_space ? item.warehouse_office_space + ' (' + item.warehouse_office_space_unit + ')' : '-'"></p>
            </v-col>

            <v-col cols="12" sm="4">
              <h6>Open Yard:</h6>
              <p v-html="item.warehouse_open_yard ? item.warehouse_open_yard + ' (' + item.warehouse_open_yard_unit + ')' : '-'"></p>
            </v-col>

            <v-col cols="12" sm="4">
              <h6>Storage/Production Area:</h6>
              <p v-html="item.warehouse_storage_area ? item.warehouse_storage_area + ' (' + item.warehouse_storage_area_unit + ')' : '-'"></p>
            </v-col>

            <v-col cols="12" sm="4">
              <h6>Power Supply:</h6>
              <p v-html="item.warehouse_power_supply ? item.warehouse_power_supply : '-'"></p>
            </v-col>

            <v-col cols="12" sm="4">
              <h6>Warehouse Insulation:</h6>
              <p v-html="item.warehouse_insulation ? item.warehouse_insulation : '-'"></p>
            </v-col>

            <v-col cols="12" sm="4">
              <h6>Ceiling Height:</h6>
              <p v-html="item.warehouse_ceiling_height ? item.warehouse_ceiling_height : '-'"></p>
            </v-col>

            <v-col cols="12" sm="4">
              <h6>Tax:</h6>
              <p v-html="item.warehouse_tax ? item.warehouse_tax : '-'"></p>
            </v-col>

            <v-col cols="12" sm="4">
              <h6>Due Date:</h6>
              <p v-html="item.warehouse_due_date ? item.warehouse_due_date : '-'"></p>
            </v-col>

            <v-col cols="12" sm="12">
              <h6>Warehouse Remarks:</h6>
              <p v-html="item.warehouse_notes ? item.warehouse_notes : '-'"></p>
              </v-textarea>
            </v-col>
            <v-col cols="12" sm="4">
              <h6>Permission Per Room Of Individuals:</h6>
              <p v-html="item.labor_camps_permission_per_room_of_individuals ? item.labor_camps_permission_per_room_of_individuals : '-'"></p>
            </v-col>

            <v-col cols="12" sm="4">
              <h6>Utilities Charges:</h6>
              <p v-html="item.labor_camps_utilities_charges ? item.labor_camps_utilities_charges : '-'"></p>
            </v-col>

            <v-col cols="12" sm="4">
              <h6>Common Area Security, Clearing:</h6>
              <p v-html="item.labor_camps_common_area_security_clearing ? item.labor_camps_common_area_security_clearing : '-'"></p>
            </v-col>

            <v-col cols="12" sm="4">
              <h6>Govt Approved Maintenance Fee:</h6>
              <p v-html="item.labor_camps_govt_approved_maintenance_fee ? item.labor_camps_govt_approved_maintenance_fee : '-'"></p>
            </v-col>
            <v-col cols="12" sm="4">
              <h6>Yearly Service Charges:</h6>
              <p v-html="item.yearly_service_charges ? item.yearly_service_charges : '-'"></p>
            </v-col>
            <v-col cols="12" sm="4">
              <h6>Monthly Cooling Charges:</h6>
              <p v-html="item.monthly_cooling_charges ? item.monthly_cooling_charges : '-'"></p>
            </v-col>
            <v-col cols="12" sm="4">
              <h6>LSM:</h6>
              <p v-html="item.lsm === 1 ? 'Private' : 'Shared'"></p>
            </v-col>
            <v-col cols="12" sm="4">
              <h6>Permit Number:</h6>
              <p v-html="item.permit_number ? item.permit_number : '-'"></p>
            </v-col>
            <v-col cols="12" sm="4">
              <h6>Permit Expiry:</h6>
              <p v-html="item.permit_expiry ? item.permit_expiry : '-'"></p>
            </v-col>
            <v-col cols="12" sm="4">
              <h6>Transaction Number:</h6>
              <p v-html="item.transaction_number ? item.transaction_number : '-'"></p>
            </v-col>
            <v-col cols="12" sm="4">
              <h6>Source:</h6>
              <p v-html="item.source ? item.source.title : '-'"></p>
            </v-col>
            <v-col cols="12" sm="4">
              <h6>Assign To:</h6>
              <p v-html="item.staff ? item.staff.name : '-'"></p>
            </v-col>
            <v-col cols="12" sm="4">
              <h6>Rented/Tenanted:</h6>
              <p v-html="item.lsm === 1 ? 'Yes' : 'No'"></p>
            </v-col>

            <v-col cols="12" sm="4">
              <h6>Tenancy From:</h6>
              <p v-html="item.tenancy_from ? item.tenancy_from : '-'"></p>
            </v-col>
            <v-col cols="12" sm="4">
              <h6>Tenancy To:</h6>
              <p v-html="item.tenancy_to ? item.tenancy_to : '-'"></p>
            </v-col>
            <v-col cols="12" sm="4">
              <h6>Tenant:</h6>
              <p v-html="item.tenant ? item.tenant.full_name : '-'"></p>
            </v-col>
            <v-col cols="12" sm="4">
              <h6>Rent Frequency:</h6>
              <p v-html="item.tenancy_rent_frequency ? item.tenancy_rent_frequency.title : '-'"></p>
            </v-col>
            <v-col cols="12" sm="4">
              <h6>Tenancy Rent:</h6>
              <p v-html="item.tenancy_rent ? item.tenancy_rent : '-'"></p>
            </v-col>
            <v-col cols="12" sm="12">
              <h6>Notes:</h6>
              <p v-html="item.notes ? item.notes : '-'"></p>
            </v-col>
          </v-row>
        </v-card-text>
      </v-tab-item>
      <v-tab-item>
        <v-card style="width: 40%; margin: 0 auto" class="mt-5 mb-5">
          <v-toolbar
              dark
              color="#00004d"
          >
            <v-toolbar-title>
              <span>Pricing Summary</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn
                  dark
                  text
                  @click.stop.prevent="copyLinkR"
              >
                <v-icon>fas fa-copy</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-card>
            <v-card-text>
              <input type="hidden" id="report-link-inner" :value="reportLink">
              <v-row v-if="item.unit_no">
                <v-col cols="12" sm="12">
                  <h6>Show Unit Number</h6>
                  <v-switch
                      v-model="showUnitNo"
                      v-bind:color="showUnitNo === 1 ? 'success': ''"
                      item-value="value"
                      hide-details
                      @change="setUnitNo($event)"
                  ></v-switch>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12">
                  <h4 v-html="item.hk"></h4>
                </v-col>
              </v-row>
              <v-row v-if="item.unit_no && showUnitNo === 1">
                <v-col cols="12" sm="6">
                  <h4>Unit Number</h4>
                </v-col>
                <v-col cols="12" sm="6">
                  <h4 class="text-right">{{ item.unit_no }}</h4>
                </v-col>
              </v-row>
              <v-row v-if="item.hasOwnProperty('purpose')">
                <v-col cols="12" sm="6">
                  <h4>Purpose</h4>
                </v-col>
                <v-col cols="12" sm="6">
                  <h4 class="text-right">{{ item.purpose.title }}</h4>
                </v-col>
              </v-row>
              <div v-if="item.purpose_id === 2">
                <v-row v-if="item.max_rent_per_annum">
                  <v-col cols="12" sm="6">
                    <h4>Rent Per Annum</h4>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <h4 class="text-right">{{ item.max_rent_per_annum }}</h4>
                  </v-col>
                </v-row>
                <v-row v-if="item.max_rent_per_annum && item.property_type && item.property_type.parent_id === 5">
                  <v-col cols="12" sm="6">
                    <h4>VAT (5%)</h4>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <h4 class="text-right">{{ getPercentage("5", item.max_rent_per_annum) }}</h4>
                  </v-col>
                </v-row>
                <v-row v-if="item.rent_agency_fee_tenant">
                  <v-col cols="12" sm="6">
                    <h4>Agency Fee Tenant ({{ item.rent_agency_fee_tenant_percentage }}%)</h4>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <h4 class="text-right">{{ item.rent_agency_fee_tenant }} + 5% VAT
                      {{ getPercentage("5", item.rent_agency_fee_tenant) }}</h4>
                  </v-col>
                </v-row>
                <v-row v-if="item.rent_agency_fee_landlord">
                  <v-col cols="12" sm="6">
                    <h4>Agency Fee Landlord ({{ item.rent_agency_fee_landlord_percentage }}%)</h4>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <h4 class="text-right">{{ item.rent_agency_fee_landlord }}</h4>
                  </v-col>
                </v-row>
                <v-row v-if="item.rent_security_fee">
                  <v-col cols="12" sm="6">
                    <h4>Security Deposit ({{ item.rent_security_fee_percentage }}%)</h4>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <h4 class="text-right">{{ item.rent_security_fee }}</h4>
                  </v-col>
                </v-row>
                <v-row v-if="item.rent_frequency">
                  <v-col cols="12" sm="6">
                    <h4>Rent Frequency</h4>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <h4 class="text-right">{{ item.rent_frequency.title }}</h4>
                  </v-col>
                </v-row>
                <v-row v-if="item.total_payable && item.total_payable > 0">
                  <v-col cols="12" sm="6">
                    <h4>Total</h4>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <h4 class="text-right">{{ addTSBV(item.total_payable) }}</h4>
                  </v-col>
                </v-row>
              </div>
              <div v-else>
                <v-row v-if="item.max_sale_price">
                  <v-col cols="12" sm="6">
                    <h4>Sale Price</h4>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <h4 class="text-right">{{ item.max_sale_price }}</h4>
                  </v-col>
                </v-row>
                <v-row v-if="item.max_sale_price && item.property_type && item.property_type.parent_id === 5">
                  <v-col cols="12" sm="6">
                    <h4>VAT (5%)</h4>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <h4 class="text-right">{{ getPercentage("5", item.max_sale_price) }}</h4>
                  </v-col>
                </v-row>
                <v-row v-if="item.sale_original_price">
                  <v-col cols="12" sm="6">
                    <h4>Original Price</h4>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <h4 class="text-right">{{ item.sale_original_price }}</h4>
                  </v-col>
                </v-row>
                <v-row v-if="item.sale_paid_of_original_price">
                  <v-col cols="12" sm="6">
                    <h4>POP ({{ item.paid_of_original_price_percentage }}%)</h4>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <h4 class="text-right">{{ item.sale_paid_of_original_price }}</h4>
                  </v-col>
                </v-row>
                <v-row v-if="item.sale_discount">
                  <v-col cols="12" sm="6">
                    <h4>Discount</h4>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <h4 class="text-right">{{ item.sale_discount }}</h4>
                  </v-col>
                </v-row>
                <v-row v-if="item.sale_premium">
                  <v-col cols="12" sm="6">
                    <h4>Premium</h4>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <h4 class="text-right">{{ item.sale_premium }}</h4>
                  </v-col>
                </v-row>
                <v-row v-if="item.sale_agency_fee_buyer && item.max_sale_price">
                  <v-col cols="12" sm="6">
                    <h4>Agency Fee Buyer ({{ item.sale_agency_fee_buyer_percentage }}%)</h4>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <h4 class="text-right">{{ item.sale_agency_fee_buyer }} + 5% VAT
                      {{ getPercentage("5", item.sale_agency_fee_buyer) }}</h4>
                  </v-col>
                </v-row>
                <v-row v-if="item.sale_agency_fee_seller && item.max_sale_price">
                  <v-col cols="12" sm="6">
                    <h4>Agency Fee Seller ({{ item.sale_agency_fee_seller_percentage }}%)</h4>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <h4 class="text-right">{{ item.sale_agency_fee_seller }} + 5% VAT
                      {{ getPercentage("5", item.sale_agency_fee_seller) }}</h4>
                  </v-col>
                </v-row>
                <v-row v-if="item.sale_transfer_fee_buyer">
                  <v-col cols="12" sm="6">
                    <h4>Transfer Fee Buyer ({{ item.sale_transfer_fee_buyer_percentage }}%)</h4>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <h4 class="text-right">{{ item.sale_transfer_fee_buyer }} <span
                        v-if="parentPropertyType && parentPropertyType === 'commercial'">+ 5% VAT {{
                        getPercentage("5", item.sale_transfer_fee_buyer)
                      }}</span>
                    </h4>
                  </v-col>
                </v-row>
                <v-row v-if="item.sale_transfer_fee_seller">
                  <v-col cols="12" sm="6">
                    <h4>Transfer Fee Seller ({{ item.sale_transfer_fee_seller_percentage }}%)</h4>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <h4 class="text-right">{{ item.sale_transfer_fee_seller }} <span
                        v-if="parentPropertyType && parentPropertyType === 'commercial'">+ 5% VAT {{
                        getPercentage("5", item.sale_transfer_fee_seller)
                      }}</span>
                    </h4>
                  </v-col>
                </v-row>
                <v-row v-if="item.sale_trustee_office_fee && item.max_sale_price">
                  <v-col cols="12" sm="6">
                    <h4>Trustee Office Fee</h4>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <h4 class="text-right">{{ item.sale_trustee_office_fee }} + 5% VAT
                      {{ getPercentage("5", item.sale_trustee_office_fee) }}</h4>
                  </v-col>
                </v-row>
                <v-row v-if="item.sale_dev_noc_fee">
                  <v-col cols="12" sm="6">
                    <h4>Developer NOC Fee</h4>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <h4 class="text-right">{{ item.sale_dev_noc_fee }} + 5% VAT
                      {{ getPercentage("5", item.sale_dev_noc_fee) }}</h4>
                  </v-col>
                </v-row>
                <v-row v-if="item.sale_legal_fee">
                  <v-col cols="12" sm="6">
                    <h4>Legal Fee ({{ item.sale_legal_fee_percentage }}%)</h4>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <h4 class="text-right">{{ item.sale_legal_fee }}</h4>
                  </v-col>
                </v-row>
                <v-row v-if="item.sale_dld_fee">
                  <v-col cols="12" sm="6">
                    <h4>Dubai Land Department Fee ({{ item.sale_dld_fee_percentage }}%)</h4>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <h4 class="text-right">{{ item.sale_dld_fee }}</h4>
                  </v-col>
                </v-row>
                <v-row v-if="item.sale_payable_now">
                  <v-col cols="12" sm="6">
                    <h4>Payable To Seller</h4>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <h4 class="text-right">{{ item.sale_payable_now }}</h4>
                  </v-col>
                </v-row>
                <v-row v-if="item.sale_payable_now_developer">
                  <v-col cols="12" sm="6">
                    <h4>Payable To Developer</h4>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <h4 class="text-right">{{ item.sale_payable_now_developer }}</h4>
                  </v-col>
                </v-row>
                <v-row v-if="item.total_payable && item.total_payable > 0">
                  <v-col cols="12" sm="6">
                    <h4>Total</h4>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <h4 class="text-right">{{ addTSBV(item.total_payable) }}</h4>
                  </v-col>
                </v-row>
              </div>
              <v-divider></v-divider>
              <v-row v-if="item.hasOwnProperty('listing_fees') && item.listing_fees.length > 0">
                <v-col cols="12" sm="12">
                  <h4>Other Fee</h4>
                </v-col>
              </v-row>
              <v-row v-if="item.hasOwnProperty('listing_fees') && item.listing_fees.length > 0"
                     v-for="(dt, i) in item.listing_fees" :key="i">
                <v-col cols="12" sm="6">
                  <h4 v-html="dt.title"></h4>
                </v-col>
                <v-col cols="12" sm="6">
                  <h4 class="text-right">
                    <span>({{ addTSBV(dt.amount) }})</span>
                    <span v-if="dt.paid_by"> Paid By: {{ dt.paid_by }}, </span>
                    <span v-if="dt.contact_id"> Paid To: {{ dt.contact.full_name }}</span>
                  </h4>
                </v-col>
              </v-row>
              <v-row v-if="item.hasOwnProperty('listing_fees') && item.listing_fees.length > 0">
                <v-col cols="12" sm="6">
                  <h4>Other Fee Total</h4>
                </v-col>
                <v-col cols="12" sm="6">
                  <h4 class="text-right">{{ addTSBV(item.total_payable_other_fee) }}</h4>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <p v-html="item.description ? item.description : '-'"></p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="4">
                <h6>Location:</h6>
                <p v-html="item.location ? item.location : '-'"></p>
              </v-col>
              <v-col cols="12" sm="4">
                <h6>Latitude:</h6>
                <p v-html="item.latitude ? item.latitude : '-'"></p>
              </v-col>
              <v-col cols="12" sm="4">
                <h6>Longitude:</h6>
                <p v-html="item.longitude ? item.longitude : '-'"></p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12">
                <gmap-map
                    :zoom="20"
                    :center="center"
                    style="width:100%;  height: 400px;"
                    :clickable="true"
                >
                  <gmap-marker
                      :key="index"
                      v-for="(m, index) in locationMarkers"
                      :position="m.position"
                  ></gmap-marker>
                </gmap-map>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <h6>Meta Title:</h6>
                <p v-html="item.meta_title ? item.meta_title : '-'"></p>
              </v-col>
              <v-col cols="12">
                <h6>Meta Keywords:</h6>
                <p v-html="item.meta_keywords ? item.meta_keywords : '-'"></p>
              </v-col>
              <v-col cols="12">
                <h6>Focus Keywords:</h6>
                <p v-html="item.focus_keywords ? item.meta_keywords : '-'"></p>
              </v-col>
              <v-col cols="12">
                <h6>Meta Description:</h6>
                <p v-html="item.meta_description ? item.meta_description : '-'"></p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-tabs
            v-model="activeTabDetailInner"
            background-color="primary" left dark>
          <v-tab>Gallery</v-tab>
          <v-tab>Brochure</v-tab>
          <v-tab>Private Documents</v-tab>

          <v-tab-item>
            <Gallery v-if="item.hasOwnProperty('gallery_files') && item.gallery_files.length > 0" :items="item.gallery_files"/>
<!--            <v-carousel-->
<!--                height="auto"-->
<!--                :continuous="false" :cycle="false" hide-delimiters-->
<!--                v-if="item.hasOwnProperty('gallery_files') && item.gallery_files.length > 0">-->
<!--              <v-carousel-item-->
<!--                  v-for="(item,i) in item.gallery_files"-->
<!--                  :key="i"-->
<!--              >-->
<!--                <v-img-->
<!--                    contain max-height="500"-->
<!--                    :src="item.file_public_url"-->
<!--                    v-if="checkFileExtension(item.extension)"-->
<!--                ></v-img>-->
<!--                <v-card height="500" v-else>-->
<!--                  <iframe-->
<!--                      onload="this.style.height = this.contentWindow.document.body.scrollHeight + 'px'"-->
<!--                      style='height: 100%; width: 100%;' frameborder="0" scrolling="no"-->
<!--                      :src='item.file_public_url' allowfullscreen></iframe>-->
<!--                </v-card>-->
<!--              </v-carousel-item>-->
<!--            </v-carousel>-->
          </v-tab-item>
          <v-tab-item>
            <v-carousel
                height="auto"
                :continuous="false" :cycle="false" hide-delimiters
                v-if="item.hasOwnProperty('brochure_files') && item.brochure_files.length > 0">
              <v-carousel-item
                  v-for="(item,i) in item.brochure_files"
                  :key="i"
              >
                <v-img
                    contain max-height="500"
                    v-if="checkFileExtension(item.extension)"
                    :src="item.file_public_url"
                ></v-img>

                <v-card height="500" v-else>
                  <iframe
                      onload="this.style.height = this.contentWindow.document.body.scrollHeight + 'px'"
                      style='height: 100%; width: 100%;' frameborder="0" scrolling="no"
                      :src='item.file_public_url' allowfullscreen></iframe>
                </v-card>
              </v-carousel-item>
            </v-carousel>
          </v-tab-item>
          <v-tab-item>
            <v-carousel
                height="auto"
                :continuous="false" :cycle="false" hide-delimiters
                v-if="item.hasOwnProperty('private_document_files') && item.private_document_files.length > 0">
              <v-carousel-item
                  v-for="(item,i) in item.private_document_files"
                  :key="i"
              >
                <v-img
                    contain max-height="500"
                    v-if="checkFileExtension(item.extension)"
                    :src="item.file_public_url"
                ></v-img>

                <v-card height="500" v-else>
                  <iframe
                      onload="this.style.height = this.contentWindow.document.body.scrollHeight + 'px'"
                      style='height: 100%; width: 100%;' frameborder="0" scrolling="no"
                      :src='item.file_public_url' allowfullscreen></iframe>
                </v-card>
              </v-carousel-item>
            </v-carousel>
          </v-tab-item>
        </v-tabs>
      </v-tab-item>
      <v-tab-item>
        <v-card flat v-if="item.hasOwnProperty('listing_features') && item.listing_features.length > 0">
          <template>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">
                    Title
                  </th>
                  <th class="text-left">
                    Floor
                  </th>
                  <th class="text-left">
                    Width
                  </th>
                  <th class="text-left">
                    Length
                  </th>
                  <th class="text-left">
                    Total
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="obj in item.listing_features"
                    :key="obj.id"
                >
                  <td>{{ obj.title }}</td>
                  <td>{{ obj.floor }}</td>
                  <td>{{ obj.width ? obj.width + ' ' + obj.width_unit : '' }}</td>
                  <td>{{ obj.length ? obj.length + ' ' + obj.length_unit : '' }}</td>
                  <td>{{ obj.total_area }}</td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </template>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import ListingFees from "@/view/pages/realstate/listings/inc/ListingFees";
import Gallery from "@/view/pages/realstate/media/Gallery";
import {SET_SNACK} from "@/core/services/store/snackbar.module";
export default {
  name: 'listing-details',
  components: {ListingFees, Gallery},
  props: ['item'],
  data() {
    return {
      showUnitNo: 0,
      reportLink: '',
      center: {},
      locationMarkers: [],
      discountOnFilter: [
        {value: "Original Price", title: 'Original Price'},
        {value: "Paid Of Original Price", title: 'Paid Of Original Price'},
      ],
    }
  },
  watch: {
    item() {
      this.activeTabDetail = 0
      this.activeTabDetailInner = 0
      this.item.latitude = this.item.latitude ? parseFloat(this.item.latitude) : 0;
      this.item.longitude = this.item.longitude ? parseFloat(this.item.longitude) : 0;
      const marker = {
        lat: this.item.latitude ? parseFloat(this.item.latitude) : 0,
        lng: this.item.longitude ? parseFloat(this.item.longitude) : 0
      };
      this.locationMarkers.push({position: marker});
      this.center = marker;
      this.reportLink = 'https://myrealtycloud.com/listing-price-report?ref=' + this.item.id + '&tkn=15s4d1e51fs48ert4g1g5gh4f'
    }
  },
  mounted() {
    this.item.latitude = this.item.latitude ? parseFloat(this.item.latitude) : 0;
    this.item.longitude = this.item.longitude ? parseFloat(this.item.longitude) : 0;
    const marker = {
      lat: this.item.latitude ? parseFloat(this.item.latitude) : 0,
      lng: this.item.longitude ? parseFloat(this.item.longitude) : 0
    };
    this.locationMarkers.push({position: marker});
    this.center = marker;
    this.reportLink = 'https://myrealtycloud.com/listing-price-report?ref=' + this.item.id + '&tkn=15s4d1e51fs48ert4g1g5gh4f'
  },
  methods: {
    setUnitNo(e) {
      if (e) {
        this.showUnitNo = 1
        this.reportLink = 'https://myrealtycloud.com/listing-price-report?ref=' + this.item.id + '&tkn=6ad4f1r5gg4b1g5h4r552d6g1'
        // this.reportLink = 'http://localhost:8080/listing-price-report?ref=' + this.item.id + '&tkn=6ad4f1r5gg4b1g5h4r552d6g1'
      } else {
        this.showUnitNo = 0
        this.reportLink = 'https://myrealtycloud.com/listing-price-report?ref=' + this.item.id + '&tkn=15s4d1e51fs48ert4g1g5gh4f'
        // this.reportLink = 'http://localhost:8080/listing-price-report?ref=' + this.item.id + '&tkn=15s4d1e51fs48ert4g1g5gh4f'
      }
    },
    copyLinkR() {
      let linkToCopy = document.querySelector('#report-link-inner')
      linkToCopy.setAttribute('type', 'text')
      linkToCopy.select()
      try {
        let successful = document.execCommand('copy');
        if (successful) {
          this.$store.dispatch(SET_SNACK, {clr: 'success', msg: 'Report Link Copied Successfully'});
        } else {
          this.$store.dispatch(SET_SNACK, {clr: 'error', msg: 'Report Link Not Copied'});
        }
      } catch (err) {
        this.$store.dispatch(SET_SNACK, {clr: 'error', msg: 'Oops, Unable To Copy'});
      }
      linkToCopy.setAttribute('type', 'hidden')
      window.getSelection().removeAllRanges()
    }
  },
  computed: {}
};
</script>