import {
    SHARED_LISTINGS_LIST,
    CLEAR_LISTINGS_ERRORS,
    UPDATE_LISTINGS_STATUS,
} from "@/core/services/store/listings.module";
import {mapState} from "vuex";
import {bus} from "@/main";
import {STAFF_LIST} from "@/core/services/store/users.module";

const sharedListingsScript = {
    name: 'shared-listings',
    components: {},
    props: ['searchFormData', 'advSHitted'],
    data() {
        return {
            mapInput: '',
            center: {},
            locationMarkers: [],
            existingPlace: null,
            headers: [
                {text: 'Title', value: 'title', name: 'title', filterable: true, sortable: false},
                {text: 'Country', value: 'country.title', name: 'country.title', filterable: false, sortable: false},
                {text: 'City', value: 'city.title', name: 'city.title', filterable: false, sortable: false},
                {text: "Created", value: "created_at", name: "created_at", filterable: false, sortable: true},
                {text: "Updated", value: "updated_at", name: "updated_at", filterable: false, sortable: false},
                {text: 'Actions', value: 'actions', name: 'actions', filterable: false, sortable: false},
            ],
            isLoaded: true,
            isLoadedContacts: false,
            draw: 1,
            searchQuery: "",
            loading: true,
            pagination: {
                page: 1,
                rowsPerPage: 25,
                totalItems: 0,
                sortDesc: "desc"
            },
            totalRecords: 0,
            rowsPerPageItems: [25, 50, 100, 150, 200],
            headerindex: "",
            staffFilter: [],
            columns: [],
            items: [],
        }
    },
    watch: {
        // params: {
        //     handler() {
        //         this.loadData().then(response => {
        //             this.items = response.items;
        //             this.totalRecords = response.total;
        //         });
        //     },
        //     deep: true
        // },
        advSHitted: {
            handler() {
                this.refreshData()
            },
            deep: true
        },
    },
    mounted() {
        for (var i = 0; i < this.headers.length; i++) {
            this.columns.push(
                '&columns[' + i + '][data]=' + this.headers[i].value +
                '&columns[' + i + '][name]=' + this.headers[i].name +
                '&columns[' + i + '][searchable]=' + this.headers[i].filterable +
                '&columns[' + i + '][orderable]=' + this.headers[i].sortable +
                '&columns[' + i + '][search][value]=' +
                '&columns[' + i + '][search][regex]=' + false
            );
        }
        this.loadData().then(response => {
            this.items = response.items;
            this.totalRecords = response.total;
        });
        bus.$on('listingUpdatedByParent', res => {
            this.loadData().then(response => {
                this.items = response.items;
                this.totalRecords = response.total;
            });
        });
    },
    methods: {
        getHeaderIndex(name) {
            let that = this;
            this.headers.filter(function (ele, i) {
                if (name == ele.name) {
                    that.headerindex = i;
                }
            });
        },
        loadData() {
            this.loading = true;
            this.isLoaded = true;
            this.items = [];
            this.mainDataTableSelection = [];
            return new Promise((resolve) => {
                let params = this.params;
                params.length = params.itemsPerPage; //set how many records to fetch per page
                params.start =
                    params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
                params.draw = this.draw;
                let query = Object.keys(params).map((key) => {
                    return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
                }).join('&');
                let searchFormDataQuery = Object.keys(this.searchFormData).map((key) => {
                    return encodeURIComponent(key) + '=' + encodeURIComponent(this.searchFormData[key])
                }).join('&');
                this.getHeaderIndex(params.sortBy[0]);
                query += '&' + searchFormDataQuery
                query +=
                    "&search[value]=" +
                    params.query +
                    "&search[regex]=" +
                    false +
                    "&order[0][column]=" +
                    (params.sortBy.length === 0 ? 3 : this.headerindex) +
                    "&order[0][dir]=" +
                    (params.sortDesc[0] ? "desc" : "asc");
                this.$store.dispatch(SHARED_LISTINGS_LIST, {q: query, columns: this.columns}).then((res) => {
                    this.draw++;
                    let items = res.records.data;
                    let total = res.records.recordsFiltered;
                    this.loading = !this.loading;
                    this.isLoaded = !this.isLoaded;
                    resolve({
                        items,
                        total
                    });
                });
            });
        },
        refreshData(loadDep = true) {
            this.loadData().then(response => {
                this.items = response.items;
                this.totalRecords = response.total;
            });
        },

        updateStatus(status, id, column) {
            this.$store.dispatch(CLEAR_LISTINGS_ERRORS);
            let data = {
                status: status,
                column: column
            };
            this.$store.dispatch(UPDATE_LISTINGS_STATUS, {'slug': id, data: data})
        },
    },
    computed: {
        params() {
            return {
                ...this.pagination,
                query: this.searchQuery
            };
        },
        ...mapState({
            errors: state => state.listings.errors
        }),
        pageCount() {
            // return this.totalRecords / this.pagination.rowsPerPage
            return this.params.itemsPerPage <= this.totalRecords ? Math.ceil(this.totalRecords / this.params.itemsPerPage) : 0
        }
    }
};
export default sharedListingsScript