<template>
  <v-col cols="12">
    <h4>{{ moduleTitle }}</h4>
    <div class="form-group" v-for="(detail,k) in details" :key="k" style="margin: 0">
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
              dense
              outlined
              label="Title"
              color="primary"
              maxlength="100"
              :counter="100"
              v-model="detail.title">
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
              dense
              outlined
              label="Floor"
              color="primary"
              maxlength="30"
              :counter="30"
              v-model="detail.floor">
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-row>
            <v-col cols="12" sm="4">
              <v-select
                  dense
                  outlined
                  :items="unitsFilterForUsers"
                  v-model="detail.width_unit"
                  item-text="title"
                  item-value="value"
                  label="Unit"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="8">
              <v-text-field
                  dense
                  outlined
                  label="Width"
                  color="primary"
                  @keypress="isNumber($event)"
                  maxlength="10"
                  :counter="10"
                  @keyup="update(detail)"
                  v-model="detail.width">
              </v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="6">
          <v-row>
            <v-col cols="12" sm="4">
              <v-select
                  dense
                  outlined
                  :items="unitsFilterForUsers"
                  v-model="detail.length_unit"
                  item-text="title"
                  item-value="value"
                  label="Unit"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="8">
              <v-text-field
                  dense
                  outlined
                  label="Length"
                  color="primary"
                  maxlength="10"
                  :counter="10"
                  @keypress="isNumber($event)"
                  @keyup="update(detail)"
                  v-model="detail.length">
              </v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field
              dense
              outlined
              label="Total"
              color="primary"
              @keypress="isNumber($event)"
              maxlength="10"
              :counter="10"
              v-model="detail.total_area">
            <template v-slot:append-outer>
              <v-btn
                  @click="remove(k)"
                  v-show="k || ( !k && details.length > 1)"
                  small
                  dark
                  color="red"
                  class="mr-1">
                <v-icon small dark>fas fa-minus-circle</v-icon>
              </v-btn>
              <v-btn
                  @click="add(k)"
                  v-show="k == details.length-1"
                  small
                  color="success"
                  class="ma-0">
                <v-icon small dark>fas fa-plus-circle</v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
    </div>
  </v-col>
</template>

<script>
import {bus} from "@/main";

export default {
  name: 'listing-features',
  props: ['moduleOf', 'moduleTitle', 'listingFeaturesOld'],
  data() {
    return {
      details: [
        {title: '', length_unit: '', width_unit: '', length: '', width: '', floor: '', total_area: ''}
      ],
      pILFilter: [],
    }
  },
  watch: {
    details: {
      handler(v) {
        let that = this;
        this.$store.state.listings.uploadedListingFeatures = v;
        v.forEach(function (v) {
          if (!v.width_unit) v.width_unit = that.$store.state.auth.user.measurement_unit
          if (!v.length_unit) v.length_unit = that.$store.state.auth.user.measurement_unit
        })
      },
      deep: true
    },
    listingFeaturesOld: {
      handler() {
        if (this.listingFeaturesOld.length > 0) {
          this.details = this.listingFeaturesOld
        } else {
          this.details = [
            {title: '', length_unit: '', width_unit: '', length: '', width: '', floor: '', total_area: ''}
          ]
        }
      },
      deep: true
    }
  },
  mounted() {
    if (this.listingFeaturesOld.length > 0) {
      this.details = this.listingFeaturesOld
    } else {
      this.details = [
        {title: '', length_unit: '', width_unit: '', length: '', width: '', floor: '', total_area: ''}
      ]
    }
    bus.$on("listingFeaturesDeletedByModal", () => {
      this.details = [
        {title: '', length_unit: '', width_unit: '', length: '', width: '', floor: '', total_area: ''}
      ]
      this.$store.state.listings.uploadedListingFeatures = [];
    });
  },
  methods: {
    add() {
      this.details.push({title: '', length_unit: '', width_unit: '', length: '', width: '', floor: '', total_area: ''});
    },
    update(detail) {
      let length = detail.length ? parseFloat(detail.length) : 0;
      let width = detail.width ? parseFloat(detail.width) : 0;
      detail.total_area = (length * width).toFixed(2)
    },
    remove(index) {
      this.details.splice(index, 1);
    },
  },
}
</script>}